<template>
  <v-card>
    <v-card-title class="text-h6">{{ $t("labels.roll_stamp") }}</v-card-title>
    <v-card-text>{{ $t("messages.enter_stamp_code_and_reason") }}</v-card-text>

    <v-card-text>
      <v-text-field
        v-model="code"
        class="c-input-xs mb-3"
        :label="$t('labels.stamp_code')"
        :placeholder="$t('labels.stamp_code')"
        autofocus
        dense
        single-line
        outlined
        clearable
        hide-details
      ></v-text-field>
      <v-textarea
        v-model="reason"
        class="c-input-xs"
        rows="3"
        :label="$t('labels.roll_reason')"
        dense
        outlined
        clearable
        hide-details
      ></v-textarea>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn
        color="green darken-1"
        text
        @click="confirm"
        :disabled="isDisabledBtn"
      >
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "RollGoodsUid",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    code: null,
    reason: null,
    isLoading: false,
  }),
  computed: {
    rollCode() {
      if (!this.item || !this.item.id) {
        return null;
      }
      return `${this.item.sku}@${this.item.uid}`;
    },
    isDisabledBtn() {
      return !this.code || !this.reason || this.rollCode !== this.code;
    },
  },
  mounted() {},
  methods: {
    async confirm() {
      if (this.isDisabledBtn) {
        return false;
      }

      if (this.isLoading) {
        this.code = null;
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/goods/v1/uid-roll", {
          code: this.code,
          reason: this.reason,
          id: this.item.id,
        });
        this.isLoading = false;
        this.code = null;
        document.getElementById("success_sound_player").play();
        this.$vToastify.success(this.$t("messages.uid_roll_success"));
        this.cancel();
        this.$emit("refreshData");
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        this.code = null;
        document.getElementById("error_sound_player").play();
      }
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped></style>
